import styled from "@emotion/styled";
import { Flex } from "@src/components/Boxes";
import { Button } from "@src/components/nessie-web";
import { Typography } from "@src/components/Typography";
import React, { useContext } from "react";
import { useAssets } from "../hooks/useAssets";
import { usePage } from "../hooks/usePage";
import { EligibilityMessage } from "./EligibilityMessage";
import { SharedStepTemplate } from "./SharedStepTemplate";
import { EligibilityReturnType } from "../hooks/useGetEligibilityMessage";
import { AppDataContext } from "@src/components/AppDataContext";
import { ModalContext, ModalType } from "@src/components/modals/ModalController";
import { Link } from "gatsby";

export const DisabledButton = styled(Button)`
  disabled: true;
  background-color: #f0f3fd;
`;

interface EligibilityProps {
  eligible: EligibilityReturnType;
}

const Eligibility = ({ eligible }: EligibilityProps) => {
  const appData = useContext(AppDataContext);
  const modalContext = useContext(ModalContext);
  const { reason, status } = eligible;
  const a = useAssets();
  const { translate } = usePage();
  const warningImg = a.cone;
  const successImg = a.smile;

  const eligibility =
    eligible.status === "success"
      ? {
          title: translate("eligibility_success_title").toString(),
          text: translate("eligibility_success_text").toString(),
          startText: translate("eligibility_start_text").toString(),
        }
      : {
          title: translate("eligibility_error_title").toString(),
          text: translate("eligibility_error_text").toString(),
          startText: translate("eligibility_start_text").toString(),
        };

  const { title, text, startText } = eligibility;

  const isOtherThanTeacher = appData.data.type !== "teacher";
  const isTeacherVerified = appData.data["teacher"]?.schoolVerified;

  const handleLogin = () => {
    modalContext.showModal(ModalType.TeacherLogin);
  };

  return (
    <SharedStepTemplate
      body={
        <>
          <Flex flexDirection="column" flex={1} gap={24} justifyContent={"center"} alignSelf="center">
            <Typography variant="Display4ExtraBold">{title}</Typography>
            {status === "error" && (
              <Typography variant="Body1" css={{ color: "#5D5D8F", textWrap: "wrap" }}>
                {text}
              </Typography>
            )}
            {reason && (
              <EligibilityMessage warningImg={warningImg} successImg={successImg} reason={reason} status={status} />
            )}
            {!isTeacherVerified && (
              <Flex justifyContent="center">
                <Link to="https://www.classdojo.com">{translate("eligibility_go_back_to_class_dojo")}</Link>
              </Flex>
            )}
            {isOtherThanTeacher && (
              <Flex justifyContent="center">
                <Button onClick={handleLogin}>{translate("eligibility_login_with_different_account")}</Button>
              </Flex>
            )}
          </Flex>
          <Flex>
            <DisabledButton disabled css={{ disabled: true }}>
              {startText}
            </DisabledButton>
          </Flex>
        </>
      }
      image={a.step_1_image}
    />
  );
};

export default Eligibility;
