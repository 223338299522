import styled from "@emotion/styled";
import { uniq } from "lodash";
import React, { CSSProperties } from "react";
import { FacePaintResponsive, mq } from "./FacePaintResponsive";
// | "DisplayOversize"

type DisplayExtraBoldNumbers = 0 | 1 | 2 | 3 | 4 | 5 | 6;
type DisplayMediumNumbers = 2 | 3 | 4 | 5;
type DisplayTypography = `Display${DisplayExtraBoldNumbers}ExtraBold` | `Display${DisplayMediumNumbers}Medium`;
type HeadlineExtraBoldNumbers = 1 | 2;
type HeadlineBoldNumbers = 3;
type HeadlineMediumNumbers = 1 | 2 | 3;
type HeadlineTypography =
  | `Headline${HeadlineExtraBoldNumbers}ExtraBold`
  | `Headline${HeadlineBoldNumbers}Bold`
  | `Headline${HeadlineMediumNumbers}Medium`;
type BodyNumbers = 1 | 2 | 3;
type BodyTypography = `Body${BodyNumbers}`;
type Mobile = "MobileHeading" | "MobileBody";
export type TypographyVariants =
  | "DisplayOversize"
  | DisplayTypography
  | HeadlineTypography
  | BodyTypography
  | Mobile
  | "label"
  | "Label1"
  | "overline"
  | "minutia";

export type LabelVariants = "label" | "Label1" | "overline" | "minutia";

export type BodyVariants = BodyNumbers;

export type DisplayVariants = "Oversize" | `${DisplayExtraBoldNumbers}ExtraBold` | `${DisplayMediumNumbers}Medium`;

export type HeadlineVariants =
  | `${HeadlineExtraBoldNumbers}ExtraBold`
  | `${HeadlineBoldNumbers}Bold`
  | `${HeadlineMediumNumbers}Medium`;

export type TypographyStyle = Pick<
  CSSProperties,
  | "color"
  | "fontFamily"
  | "fontSize"
  | "fontWeight"
  | "lineHeight"
  | "letterSpacing"
  | "textTransform"
  | "fontStyle"
  | "fontFeatureSettings"
>;

export type TypographyTheme = Record<TypographyVariants, TypographyStyle>;

export interface ITypographyProps {
  variant: FacePaintResponsive<TypographyVariants>;
  center?: boolean;
  color?: string;
}

export const Typography = styled("p")<ITypographyProps>(({ variant, center, color, theme }) => {
  const variantArr = Array.isArray(variant) ? variant : [variant];
  const keys = uniq(variantArr.map((v) => Object.keys(theme.__new.typography[v])).flat());
  let styles = keys.reduce((acc, key) => {
    const values = variantArr.map((v) => theme.__new.typography[v][key]);
    return { ...acc, [key]: values };
  }, {});

  let hexColor = undefined;

  if (color) {
    hexColor = theme.__new.colors[color] ?? color;
    styles = {
      ...styles,
      color: hexColor,
    };
  }

  return mq({
    margin: 0,
    padding: 0,
    textWrap: "balance",
    textAlign: center ? "center" : undefined,
    ...styles,
  });
});

export type TypographyProps = React.ComponentProps<typeof Typography>;
